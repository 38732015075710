<template>
    <v-container fluid style="padding-bottom:80px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
        </v-col>
      </v-row>
      <v-row>
            <v-col cols="12">
                <v-card flat>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="office"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    label="Office"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="maint_section"
                                    :items="maint_sections"
                                    item-value="section_id"
                                    item-text="descr"
                                    label="Section"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="getPartMach()"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="part"
                                    :items="parts"
                                    item-value="part_id"
                                    item-text="descr"
                                    label="Part of Mach"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-btn class="border-12 mt-1" color="info" elevation="2" style="padding: 15px;" @click="getPullData()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>        
            <v-col cols="12">
                <v-tabs class="mt-3" height="32" v-model="tab">
                    <v-tab :href="`#tab-1`">
                        Master Checklist
                    </v-tab>                              
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">    
                        <v-card outlined>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-title>
                                            <div class="d-flex w-100">
                                                Result
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                small
                                                color="success"
                                                class="py-5"
                                                @click="dialog = true"
                                                >
                                                    Create New
                                                </v-btn>
                                            </div>                                            
                                        </v-card-title>
                                        <v-data-table
                                            dense
                                            :headers="headers"
                                            :items="items"
                                            class="elevation-1 mt-2"
                                            :items-per-page="15"
                                            height="578"
                                            fixed-header
                                            :divider="true"
                                            :light="true"
                                        >
                                            <template v-slot:[`item.active_flag`]="{ item }">
                                                <v-switch
                                                    dense
                                                    inset
                                                    false-value="N"
                                                    true-value="Y"
                                                    v-model="item.active_flag"
                                                    class="mt-1 mb-2"
                                                    hide-details="true"
                                                    @change="updateStatus(item)"
                                                ></v-switch>
                                            </template>
                                            <template v-slot:[`item.min`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.min) }}
                                            </template>
                                            <template v-slot:[`item.max`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.max) }}
                                            </template>
                                            <template v-slot:[`item.normal`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.normal) }}
                                            </template>
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <v-menu
                                                    bottom
                                                    left
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            dark
                                                            icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            color="grey"
                                                            class="text-center"
                                                        >
                                                            <v-icon>mdi-dots-vertical</v-icon>
                                                        </v-btn>
                                                    </template>

                                                    <v-list dense>
                                                        <v-list-item @click="edit(item)">
                                                            <v-list-item-icon>
                                                                <v-icon>mdi-pencil</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Edit</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </template>
                                        </v-data-table>
                                        <v-dialog
                                            v-model="dialog"
                                            persistent
                                            max-width="500px"
                                            hide-overlay
                                            transition="dialog-bottom-transition"
                                            style="border-radius: 0 !important"
                                        >
                                            <v-card class="p-2 rounded-l">
                                                <v-card-title class="text-center">
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <div class="d-flex">
                                                                <div class="ml-5">
                                                                    Checklist
                                                                </div>
                                                                <v-spacer></v-spacer>
                                                                <v-btn
                                                                    class="mx-2"
                                                                    fab
                                                                    x-small
                                                                    color="#e83e8c;"
                                                                    @click="[dialog = false, clear()]"
                                                                >
                                                                    <v-icon dark>
                                                                        mdi-close
                                                                    </v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </v-col>
                                                        <v-divider></v-divider>
                                                    </v-row>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container fluid>
                                                        <v-row>
                                                            <v-col class="col-6">
                                                                <h6 class="blue-lcd mb-1 font-12">Office <strong style="color:red;">*</strong><span></span></h6>
                                                                <v-autocomplete
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    v-model="office_id"
                                                                    :items="offices"
                                                                    item-value="office_id"
                                                                    item-text="office"
                                                                    class="ma-0 pa-0 border-12"
                                                                    hide-details=true
                                                                    :disabled="status_save == 'update' ? true : false"
                                                                ></v-autocomplete>
                                                            </v-col>
                                                            <v-col class="col-6">
                                                                <h6 class="blue-lcd mb-1 font-12">Section <strong style="color:red;">*</strong><span></span></h6>
                                                                <v-autocomplete
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    v-model="section_id"
                                                                    :items="maint_sections"
                                                                    item-value="section_id"
                                                                    item-text="descr"
                                                                    class="ma-0 pa-0 border-12"
                                                                    hide-details=true
                                                                    @change="getPartMachId()"
                                                                    :disabled="status_save == 'update' ? true : false"
                                                                ></v-autocomplete>
                                                            </v-col>
                                                            <v-col class="col-12">
                                                                <h6 class="blue-lcd mb-1 font-12">Part of Mach <strong style="color:red;">*</strong><span></span></h6>
                                                                <v-autocomplete
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    v-model="part_id"
                                                                    :items="parts"
                                                                    item-value="part_id"
                                                                    item-text="descr"
                                                                    class="ma-0 pa-0 border-12"
                                                                    hide-details=true
                                                                    :disabled="status_save == 'update' ? true : false"
                                                                ></v-autocomplete>
                                                            </v-col>                                                            
                                                            <v-col class="col-12" sm="12" md="12">
                                                                <h6 class="blue-lcd mb-1 font-12">Checklist Descr <strong style="color:red;">*</strong><span></span></h6>
                                                                <v-text-field
                                                                    outlined
                                                                    dense
                                                                    persistent-hint
                                                                    v-model="description"
                                                                    class="ma-0 pa-0 border-12"
                                                                    hide-details=true
                                                                >
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col class="col-6">
                                                                <h6 class="blue-lcd mb-1 font-12">Data Type <strong style="color:red;">*</strong><span></span></h6>
                                                                <v-autocomplete
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    v-model="type"
                                                                    :items="types"
                                                                    item-value="value"
                                                                    item-text="text"
                                                                    class="ma-0 pa-0 border-12"
                                                                    hide-details=true
                                                                    @change="changeNumPeriod()"
                                                                ></v-autocomplete>
                                                            </v-col>                                                            
                                                            <v-col class="col-6">
                                                                <h6 class="blue-lcd mb-1 font-12">Status <strong style="color:red;">*</strong><span></span></h6>
                                                                <v-switch
                                                                    dense
                                                                    inset
                                                                    false-value="N"
                                                                    true-value="Y"
                                                                    v-model="active_flag"
                                                                    class="mt-2 ml-3 border-12"
                                                                    hide-details="true"
                                                                ></v-switch>
                                                            </v-col>
                                                            <v-col class="col-4">
                                                                <h6 class="blue-lcd mb-1 font-12">Min <strong style="color:red;">*</strong><span></span></h6>
                                                                <v-text-field
                                                                    outlined
                                                                    dense
                                                                    persistent-hint
                                                                    v-model="min"
                                                                    class="ma-0 pa-0 border-12"
                                                                    hide-details=true
                                                                    type="number"
                                                                >
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col class="col-4">
                                                                <h6 class="blue-lcd mb-1 font-12">Max <strong style="color:red;">*</strong><span></span></h6>
                                                                <v-text-field
                                                                    outlined
                                                                    dense
                                                                    persistent-hint
                                                                    v-model="max"
                                                                    class="ma-0 pa-0 border-12"
                                                                    hide-details=true
                                                                    type="number"
                                                                >
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col class="col-4">
                                                                <h6 class="blue-lcd mb-1 font-12">Normal <strong style="color:red;">*</strong><span></span></h6>
                                                                <v-text-field
                                                                    outlined
                                                                    dense
                                                                    persistent-hint
                                                                    v-model="normal"
                                                                    class="ma-0 pa-0 border-12"
                                                                    hide-details=true
                                                                    type="number"
                                                                >
                                                                </v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions class="text-center">
                                                    <v-row justify="center">
                                                        <v-col cols="12" xs="12" sm="3" md="3">
                                                            <v-btn block class="rounded-l mr-2" color="#fff" @click="[dialog = false, clear()]">Cancel</v-btn>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="3" md="3" v-if="status_save == 'store'">
                                                            <v-btn block class="rounded-l text-white" color="success" @click="store()">Save</v-btn>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="3" md="3" v-if="status_save == 'update'">
                                                            <v-btn block class="rounded-l text-white" color="success" @click="update()">Update</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            currentSlide: 0,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Surya Maju Sukses',
                disabled: false,
                href: '/admin/sms',
                },
                {
                text: 'Maintenance',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Master Checklist',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            dialogdetails:false,
            offices: [],
            office_id: '',
            office: '',
            maint_section: '',
            section_id: '',
            maint_sections: [],
            parts: [],
            part_id: '',
            part: '',
            type: '',
            types: [
                {text: 'CHECK', value: 'CHECK'},
                {text: 'NUMERIC', value: 'NUMERIC'},
                {text: 'STRING', value: 'STRING'},
            ],
            min: 0,
            max: 0,
            normal: 0,
            tab: 'tab-1',
            items: [],
            headers: [
                { text: 'Office', align: 'start', sortable: true, value: 'office' },
                { text: 'Section', align: 'start', sortable: true, value: 'main_section' },
                { text: 'Part of Mach', align: 'start', sortable: true, value: 'part_of_mach' },
                { text: 'Checklist', align: 'start', sortable: true, value: 'check_descr' },
                { text: 'Data Type', align: 'start', sortable: true, value: 'data_type' },
                { text: 'Min', align: 'end', sortable: true, value: 'min' },
                { text: 'Max', align: 'end', sortable: true, value: 'max' },
                { text: 'Normal', align: 'end', sortable: true, value: 'normal' },
                { text: 'Status', align: 'start', sortable: true, value: 'active_flag' },
                { text: '', groupable: false,  value: 'actions', sortable: false },
            ],
            dialog: false,
            description: '',
            active_flag: 'Y',
            detail: null,
            status_save: 'store'
        }
    },
    computed: {
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getOffice()
        await this.getMaintSection()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        clear(){
            this.office_id = ''
            this.section_id = ''
            this.part_id = ''
            this.description = ''
            this.type = ''
            this.min = 0
            this.max = 0
            this.normal = 0
            this.active_flag = 'Y'
            this.status_save = 'store'
        },
        changeNumPeriod(){
            this.num_period = 0
        },
        async getOffice(){
            await axios.get(`${process.env.VUE_APP_URL}/api/v3/sms/maintenance/filter/office`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.office = '3'
                this.offices = res.data.data
            });
        },
        async getMaintSection(){
            await axios.get(`${process.env.VUE_APP_URL}/api/v3/sms/maintenance/filter/maint-section`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.maint_sections = res.data.data
            });
        },
        async getPartMach(){
            if (this.maint_section) {
                await axios.get(`${process.env.VUE_APP_URL}/api/v3/sms/maintenance/master-part?office_id=${this.office ? this.office : ""}&section_id=${this.maint_section ? this.maint_section : ""}&active_flag=Y`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.parts = res.data.data
                });
            } else {
                this.maint_section = ''
                this.part = ''
            }
        },
        async getPartMachId(){
            if (this.section_id) {
                await axios.get(`${process.env.VUE_APP_URL}/api/v3/sms/maintenance/master-part?office_id=${this.office_id ? this.office_id : ""}&section_id=${this.section_id ? this.section_id : ""}&active_flag=Y`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.parts = res.data.data
                });
            } else {
                this.section_id = ''
                this.part_id = ''
            }
        },
        async getPullData(){
            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/v3/sms/maintenance/master-checklist?office_id=${this.office ? this.office : ""}&section_id=${this.maint_section ? this.maint_section : ""}&part_id=${this.part ? this.part : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.items  = res.data.data
                this.$store.dispatch('setOverlay', false)
            })
        },
        async store(){
            this.$store.dispatch('setOverlay', true)
            this.dialog = false

            if (this.office_id === '' || this.section_id === '' || this.part_id === '' || this.description === '' || this.type === '' || this.active_flag == '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please fill your fields and submit for save',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                this.dialog = true

                return false
            } else {
                await axios.post(`${process.env.VUE_APP_URL}/api/v3/sms/maintenance/master-checklist/store`, { 
                        office_id: this.office_id ? this.office_id : '',
                        section_id: this.section_id ? this.section_id : '',
                        part_id: this.part_id ? this.part_id : '',
                        descr: this.description ? this.description : '',
                        data_type: this.type ? this.type : '',
                        active_flag: this.active_flag ? this.active_flag : '',
                        min: this.min ? this.min : 0,
                        max: this.max ? this.max : 0,
                        normal: this.normal ? this.normal : 0
                    },
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    }
                )
                .then(res => {
                    this.$store.dispatch('setOverlay', false)
                    Swal.fire({
                        text: 'Data submitted successfully',
                        icon: 'success',
                    })
                    this.clear()
                    this.dialog = false
                    this.getPullData()
                })
                .catch(err => {
                    this.$store.dispatch('setOverlay', false)
                    Swal.fire({
                        text: err.response.data,
                        icon: 'error',
                    })
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }
                })
            }
        },
        async updateStatus(item){
            this.$store.dispatch('setOverlay', true)
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/sms/maintenance/master-checklist/update`, { 
                    office_id: item.office_id ? item.office_id : '',
                    section_id: item.section_id ? item.section_id : '',
                    part_id: item.part_id ? item.part_id : '',
                    check_id: item.check_id,
                    descr: item.check_descr ? item.check_descr : '',
                    data_type: item.data_type ? item.data_type : '',
                    active_flag: item.active_flag ? item.active_flag : '',
                    min: item.min ? item.min : 0,
                    max: item.max ? item.max : 0,
                    normal: item.normal ? item.normal : 0
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                this.$store.dispatch('setOverlay', false)
                Swal.fire({
                    text: 'Data update successfully',
                    icon: 'success',
                })
                this.clear()
                this.dialog = false
                this.getPullData()
            })
            .catch(err => {
                this.$store.dispatch('setOverlay', false)
                Swal.fire({
                    text: err.response.data,
                    icon: 'error',
                })
                if(err.response.status == '401'){
                    this.$router.push('/');
                }
            })
        },
        async edit(item){
            this.detail = item
            this.office_id = item.office_id
            this.section_id = item.section_id.trim()
            this.part_id = item.part_id
            await this.getPartMachId()
            this.description = item.check_descr
            this.type = item.data_type
            this.min = item.min
            this.max = item.max
            this.normal = item.normal
            this.active_flag = item.active_flag
            this.status_save = 'update'
            this.dialog = true
        },
        async update(){
            this.$store.dispatch('setOverlay', true)
            this.dialog = false

            if (this.office_id === '' || this.section_id === '' || this.part_id === '' || this.description === '' || this.type === '' || this.active_flag == '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please fill your fields and submit for save',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                this.dialog = true

                return false
            } else {
                await axios.post(`${process.env.VUE_APP_URL}/api/v3/sms/maintenance/master-checklist/update`, { 
                        office_id: this.office_id ? this.office_id : '',
                        section_id: this.section_id ? this.section_id : '',
                        part_id: this.part_id ? this.part_id : '',
                        descr: this.description ? this.description : '',
                        data_type: this.type ? this.type : '',
                        active_flag: this.active_flag ? this.active_flag : '',
                        min: this.min ? this.min : 0,
                        max: this.max ? this.max : 0,
                        normal: this.normal ? this.normal : 0,
                        check_id: this.detail.check_id,
                    },
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    }
                )
                .then(res => {
                    this.$store.dispatch('setOverlay', false)
                    Swal.fire({
                        text: 'Data update successfully',
                        icon: 'success',
                    })
                    this.clear()
                    this.dialog = false
                    this.getPullData()
                })
                .catch(err => {
                    this.$store.dispatch('setOverlay', false)
                    Swal.fire({
                        text: err.response.data,
                        icon: 'error',
                    })
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }
                })
            }
        }
    },
    watch: {
    }
}
</script>
<style>
@media screen {
  #printSectionSalesActivityKbt {
      display: none;
  }
}

@media print {
  body * {
    visibility:hidden;
  }
  #printSectionSalesActivityKbt, #printSectionSalesActivityKbt * {
    visibility:visible;
  }
  #printSectionSalesActivityKbt {
    position:absolute;
    left:0;
    top:0;
  }
}
</style>